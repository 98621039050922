import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import AboutUs from "../components/text-components/about-us.js"
import Testimonial from "../components/hero-components/testimonials.js"
import Process from "../components/text-components/flooring-process.js"
import Products from "../components/text-components/products.js"
import CTA from "../components/hero-components/call-to-action.js"
import CalendlyHero from "../components/calendly/calendly-hero.js"

const BookingPage = () => (
  <Layout>
    <CalendlyHero
      heroHeading="Book a home visit"
      heroParagraph="Use our booking system below to arrange a measure and view our samples."
      heroImage="/content/hero-1.png"
    />
    <div className="footer"></div>
  </Layout>
)

const CalendlyContainer = styled.div`
  width: 100%;
  min-width: 100%;
  height: 1000px;
`

export default BookingPage
