import React from "react"
import styled from "styled-components"

class Calendly extends React.Component {
  componentDidMount() {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  }

  componentWillUnmount() {
    // whatever you need to cleanup the widgets code
  }

  render() {
    return (
      <CalendlyWrapper id="schedule_form">
        <CalendlyWidget
          className="calendly-inline-widget"
          data-url="https://calendly.com/cannadines-carpets/book-a-measure?text_color=1a1a1a&primary_color=206886"
        />
      </CalendlyWrapper>
    )
  }
}

const CalendlyWidget = styled.div`
  max-width: 100%;
  width: 1200px;
  height: 720px;
  max-height: 100%;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 480px;
    height: 1200px;
    padding: 0 24px;
    box-sizing: border-box;
  }
`

const CalendlyWrapper = styled.div``

export default Calendly
